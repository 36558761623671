import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { ContactForm } from '/src/components/contact'

const ContactPage = () => {
  return (
    <Layout hero={<HeroImage title="Contact us" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Orrcon Steel is an Australian steel distribution business that is into everything in steel.
                Our extensive product range includes livestock handling equipment, building materials, structural and tubular steel, fencing, roofing, aluminium and more.
                We pride ourselves on delivering the highest levels of customer service and quality products. We have huge stock holdings and our products can be cut to length and delivered to your site.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <ContactForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const Head = () => (
  <>
    <title>Contact | Orrcon Steel</title>
    <meta name="description" content="Online Account Credit Application process." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)
